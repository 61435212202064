﻿@import "variables";
@import "mixins/functions";

/********** General **********/
html, body {
    width: auto !important;
    overflow-x: hidden !important;
}


body {
    font-family: Lato, san-serif;
    font-weight: 300;
    overflow-x: hidden;
    font-size: 18px;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
}

.highlight, hr {
    color: #f8a331;
    border-color: #f8a331;
}


a.highlight, a.highlight:hover, .breadcrumb a {
    color: #f8a331;
}

.row {
    margin-top: 15px;
    margin-bottom: 15px;
}

.ctr {
    text-align: center;
}

.right {
    text-align: right;
}

.lge {
    font-size: 1.25em;
}

.xlge {
    font-size: 2.5em;
}

.upper {
    text-transform: uppercase;
}

.white {
    color: white;
}

.grey {
    color: #3f494f;
}

.moveup {
    margin-top: -50px !important;
    position: relative;
    z-index: -1;
}

.greybg {
    background-color: #3f494f;
    color: white;
}

.orangebg {
    background-color: #f8a331;
    color: white;
}

.transbg {
    background-color: rgba(255, 255, 255, 0.75);
}

.transbg-2 {
    background: rgba(255, 255, 255, 0.75);
    height: 350px;
}

.bordertop {
    border-top: 1px solid #f8a331;
}

.lowermargin {
    background-position-y: -200px !important;
}

.patterned {
	background: url("/images/2015/pattern.jpg") top center;
    background-size: cover;
}

.patterned3 {
	background: url('/images/pattern3.jpg') top center no-repeat;
    background-size: cover;
}

.patterned4 {
    background: url('/images/2015/blurred-bg.jpeg');
    background-size: cover;
}

.lightgreybg {
    background-color: #eaeaea;
    color: #3f494f;
}

.gutterrow {
    height: 50px;
}

.padded {
    padding-top: 80px;
    padding-bottom: 80px;
}

.padded-deep {
    padding: 120px 0;
}

.padded-shallow {
    padding: 30px 0;
}

.padded-top {
    padding-top: 50px;
}

.padded-bottom {
    padding-bottom: 25px;
}

.no-padding {
    padding: 0 !important;
}

.borderright {
    border-right: 1px solid #f8a331;
}

.bottom {
    position: absolute;
    bottom: 0;
}

input.transparent-input, textarea.transparent-input {
    background-color: rgba(255,255,255,0.5) !important;
}

.form-control::-moz-placeholder {
    color: white;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: white;
}

.form-control::-webkit-input-placeholder {
    color: white;
}

.form-control {
    margin-bottom: 12px;
}

.darken {
    -webkit-filter: brightness(0);
    -webkit-filter: invert(0.75);
    -webkit-filter: grayscale(100%);
    // filter: brightness(0%);
}

.partner-sect .row {
    margin-bottom: 0;
}

.partner-sect .nav-tabs > li > a {
    color: #f8a331;
}

.partner-sect #hardware .col-md-4, .partner-sect #software .col-md-4, .partner-sect #cloud .col-md-4 {
    min-height: 400px;
}



.skew-container, .skew-container-dark, .skew-container-light {
    position: relative;
}


.skew-container:before, .skew-container-dark:before {
    content: '';
    position: absolute;
    left: 0;
    top: -45px;
    width: 110%;
    height: 100px;
    background: #3f494f;
    -webkit-transform: skewY(-2.5deg);
    -moz-transform: skewY(-2.5deg);
    -ms-transform: skewY(-2.5deg);
    -o-transform: skewY(-2.5deg);
    transform: skewY(-2.5deg);
}

.skew-container-dark:after {
    content: '';
    position: absolute;
    left: 0;
    margin-bottom: -50px;
    bottom: 0;
    width: 110%;
    height: 100px;
    background: #3f494f;
    -webkit-transform: skewY(-2.5deg);
    -moz-transform: skewY(-2.5deg);
    -ms-transform: skewY(-2.5deg);
    -o-transform: skewY(-2.5deg);
    transform: skewY(-2.5deg);
}

.skew-container-light:before {
    content: '';
    position: absolute;
    left: 0;
    top: -45px;
    width: 110%;
    height: 100px;
    background: #eaeaea;
    -webkit-transform: skewY(-2.5deg);
    -moz-transform: skewY(-2.5deg);
    -ms-transform: skewY(-2.5deg);
    -o-transform: skewY(-2.5deg);
    transform: skewY(-2.5deg);
}

@media (max-width: 767px) {

    .navbar-nav {
        display: inline;
    }

    .navbar-default .navbar-brand {
        display: inline;
    }

    .navbar-default .navbar-toggle .icon-bar {
        background-color: #fff;
    }

    .navbar-default .navbar-nav .dropdown-menu > li > a {
        color: red;
        background-color: #ccc;
        //border-radius: 4px;
        margin-top: 2px;
    }

    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: white;
        background-color: transparent;
    }

    .navbar-default .navbar-nav > .open > a {
        color: orange;
        background-color: transparent;
    }

    .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
        background-color: #ccc;
    }



    .navbar-nav .open .dropdown-menu {
        /*border-bottom: 1px solid white;*/
        border-radius: 0;
    }

    .dropdown-menu {
        padding-left: 10px;
    }

    .dropdown-menu .dropdown-menu {
        padding-left: 20px;
    }

    .dropdown-menu .dropdown-menu .dropdown-menu {
        padding-left: 30px;
    }

    li.dropdown.open {
        border: 0 solid red;
    }

    .hero {
        padding-top: 10vh;
    }

    .hero h1 {
        font-size: 30px;
    }

    .hero h2 {
        font-size: 20px;
    }

    .icon-blk {
        min-height: 200px;
    }

    .defaultcallout .server img {
        width: 95%;
    }

    .cat-sect {
        text-align: center;
    }

    .cat-sect img {
        width: 90%;
    }


    .patterned {
        background-position-y: 0;
        background-size: cover;
    }

    .partner-sect .nav > li > a {
        padding: 10px 10px;
    }
}

@media (min-width: 768px) {
    ul.nav li:hover > ul.dropdown-menu {
        display: block;
    }

    navbar-nav > li:hover {
        color: orange;
    }

    #navbar {
        text-align: center;
    }

    .nav > li :hover {
        color: orange;
    }

    #header .nav > li > a:active {
        pointer-events: none;
    }

    /*#clicknotenabled {
        pointer-events: none;
        color: #f8a331 !important;
    }*/
    #clickenable {
        pointer-events: auto !important;
    }
}


.navbar-fixed-top .navbar-collapse,
.navbar-fixed-bottom .navbar-collapse {
    max-height: 600px;
}

@media only screen and (min-width: 300px) {
    .elementToClickInMobile {
        pointer-events: none;
        background-color: transparent;
    }

    .quote {
        font-size: 26px;
        font-style: italic;
        padding: 30px 0;
    }

    .padded {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .padded-deep {
        padding: 30px 0;
    }

    .padded-top {
        padding-top: 15px;
        padding-bottom: 20px;
    }
}
/*** CATEGORY SECTIONS ***/
section.cat-sect-pattern {
    background-image: url("/images/2015/cat-sect-pattern.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 350px;
}

section.cat-sect-pattern-2 {
    background-image: url('/images/2015/hpc_header.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 350px;
}

div.cat-sect-pattern-3 {
    background-image: url("/images/2015/cloud_header.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 350px;
}

.leftangled {
    max-height: 350px;
}

.leftangled:before {
    content: '';
    line-height: 0;
    font-size: 0;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 350px solid rgba(255,255,255,0.75);
    border-left: 50px solid transparent;
    border-right: 0 solid rgba(255,255,255,0.75);
    position: absolute;
    top: -50px;
    left: -50px;
}

.product-shot {
    margin-top: -125px;
}

.product-shot img {
    max-width: 550px;
}

.breadcrumb {
    background-color: transparent;
    padding: 8px 15px 0 15px;
}

.quote {
    font-size: 2em;
    font-style: italic;
    padding: 30px 0;
}

.benefits p {
    margin: 20px 0;
}

.entry-content p {
    margin-bottom: 20px;
}

.blogdata a {
    color: #f8a331;
}
/*** HP Sections ***/

.expertise .icon {
    width: 50%;
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
}


.whatwedo .sect, .sect {
    margin-bottom: 50px;
    display: inline-block;
}

.callout {
    margin-top: 75px;
}

.defaultcallout .server img {
    width: 75%;
    margin-top: 30px;
}

.morefrom a:hover {
    text-decoration: none;
}

.morefrom a img {
    border: 2px solid white;
    margin-bottom: 20px;
}

.morefrom a:hover img {
    border: 2px solid #f8a331;
}

﻿@import "variables";
@import "mixins/functions";


.footer a {
	color: #f8a331;
}

.smalltxt {
	font-size: 0.8em;
}

table {
	width: 100%;
	//font-size: 1.2em;
th {
	border-bottom: 1px solid #ddd;
   }
tbody>tr>td 
    {
border-top: 0;
   }
}

.bloglisting 
{
padding-bottom: 50px;
.sect {
	min-height: 300px;
       }
.entry-content img {
	max-width: 100%;
       }
}

.entry-content table 
{
td {
	padding: 5px 0 !important;
   }
tr {
	background-color: white !important;
	border-top: 1px solid #ccc !important;
	padding: 10px 0 !important;
  }
}


.cat-sect .col-md-4 img {
	max-height: 280px;
}




﻿@import "variables";
@import "mixins/functions";

/*** HERO ***/
section.module.parallax, div.parallax {
    //height: 600px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-attachment: fixed;
    background-size: cover;

}

@media screen and (max-device-width: 768px){
    section.module.parallax, div.parallax {
        background-attachment: scroll;
    }
}

div.parallax-0 {
	background-image: url('/images/2015/pattern2.jpg');
}

section.module.parallax-1 {
	background-image: url("/images/2015/city.jpg");
}

section.module.parallax-2 {
	background-image: url("/images/finance_header.jpg");
}

section.module.parallax-3, section.module.parallax-converged {
	background-image: url("/images/2015/converged_header.jpg");
}

section.module.parallax-4 {
	background-image: url("/images/2015/partner_header.jpg");
}

section.module.parallax-5 {
	background-image: url('/images/2015/hpc_header.jpg');
}

section.module.parallax-6 {
	background-image: url('/images/2015/cloud_header.jpg');
}
 section.module.parallax-labs {
 background-image: url("/images/2015/categorypage-bioslabs.jpg");
 }
section.module.parallax-default {
	background-image: url("/images/2015/default_header.jpg");
}

section.module.parallax-contact {
	background-image: url("/images/2015/default_header.jpg ");
}

.hero {
    h1{
    font-size: 3em;
    }
    h2{
     line-height: 1.25em;
    }
}

.cathero {
	height: 60vh;
	padding-top: 10vh;
	position: relative;
	min-height: 500px;
}

#carousel a:hover{
    color: initial;
    background-color: #fff;
}
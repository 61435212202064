﻿td {
	border: 1px solid rgba(179, 186, 190, 0.05) !important;
}

.Viewallbutton {
	color: #f8a331;
	background-color: transparent;
	border-color: #f8a331;
	line-height: 1.42857143;
	border: 1px solid;
	border-radius: 4px;
}
  .img-holder {
margin:0 auto;
margin:20px 0;
border-top:1px solid #ddd;
border-bottom:1px solid #ddd;
padding-top:20px;
display:inline-block;
text-align:center;
}

.caption {
text-align:center;
font-style:italic;
color:#9d9d9d;
padding:10px 0px 10px 0px;
margin:0px;
font-size:0.75em;
}
.a{
    color:orange;
}

.cat-sect {
    margin-top: 0px;
    margin-bottom: 0px;
}
﻿@import "variables";
@import "mixins/functions";

.hero {
    height: 70vh;
}

.carousel-style {
    h1 {
        color: white;
    }

    p {
        color: white;
    }

    a {
        margin: 10px 0;
    }
}

.header-orange h1, .paragraph-orange p {
    color: #f8a331;
}
.header-white h1, .paragraph-white p {
    color: white;
}
.header-black h1, .paragraph-black p {
    color: black;
}
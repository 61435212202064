﻿@import "variables";
@import "mixins/functions";

.nav {
    padding: 0 50px 0 50px;
}

.navbar {
    margin-bottom: 0;
    border: 0;
}

.navbar-brand {
    padding: 5px;

    img {
        height: 90%;
        margin-left: 10px;
    }
}

.navbar-default {
    .navbar-nav > li > a {
        color: white;
        padding-right: 11px !important;
        padding-left: 11px !important;
    }
}

.navbar-default .navbar-nav > li > a:hover {
    color: #f8a331;
}


.dropdown-menu {
    border-radius: 0;

    li.kopie > a {
        padding-left: 5px;
    }

    li > a:hover > .dropdown-menu > .active > a:hover {
        text-decoration: underline;
    }
}



.dropdown-submenu {
    position: relative;

    .dropdown-menu {
        top: 0;
        left: 100%;
        margin-top: -6px;
        margin-left: -1px;
    }

    a:after {
        border-color: transparent transparent transparent #333;
        border-style: solid;
        border-width: 5px 0 5px 5px;
        content: " ";
        display: block;
        float: right;
        height: 0;
        margin-right: -10px;
        margin-top: 5px;
        width: 0;
    }

    :hover > a:after {
        border-left-color: #555;
    }
}

.navbar-default .navbar-nav > li > a {
    color: white !important;
    padding-right: 11px !important;
    padding-left: 11px !important;
    background: transparent !important;
}

.navbar-nav .open .dropdown-menu {
    border-bottom: 0px;
    border-radius: 0;
}

.navbar-default .navbar-nav > .open > a:hover {
    background-color: transparent !important;
}

.navbar-default .navbar-nav > li > a:hover {
    color: #f8a331 !important;
}

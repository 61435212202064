﻿@import "variables";
@import "mixins/functions";

/*** BUTTONS ***/
   .btn-white{
	color: #fff;
	background-color: transparent;
	border-color: #fff;
   :hover,:focus,:active,a.active{
   color: #f8a331;
   border-color: #f8a331;
    }
}

.btn-grey {
	color: #3f494f;
	background-color: transparent;
	border-color: #3f494f;
    :hover,:focus,:active{
     color: #fff;
	background-color: transparent;
	border-color: #fff;
           }
    }

.btn-highlight 
{
	color: #f8a331;
	background-color: transparent;
	border-color: #f8a331;
    :hover
        {
     color: #3f494f;
	background-color: transparent;
	border-color: #3f494f;
        }
}

.circle {
	border-radius: 50%;
	width: 100px;
	height: 100px;
	text-align: center;
	color: white;
	font-size: 2.5em;
	padding-top: 18px;
	margin: 0 auto;
	margin-bottom: 30px;
}


﻿@import "variables";
@import "mixins/functions";

.img-holder {
    margin: 0 auto;
    margin: 20px 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding-top: 20px;
    display: inline-block;
    text-align: center;
}

.img-holder img {
    margin: 0 auto;
}

.caption {
    text-align: center;
    font-style: italic;
    color: #9d9d9d;
    padding: 10px 0 10px 0;
    margin: 0;
    font-size: 0.75em;
}
 a{
     color:orange;
 }